<template>
    <div v-if="loading" class="d-flex align-items-center">
        <div class="spinner-border" role="status"></div>
        <span class="ms-1">Loading...</span>
    </div>
    <div v-else-if="isSelectable">
        <button v-if="!showSelector" @click="startSearching" type="button" class="btn btn-link" style="padding-left: 0; text-decoration: underline">
            {{ selectedUserDisplayName ?? 'Assign' }}
        </button>
        <div v-else>
            <vue3-simple-typeahead
                style="margin-top: 7px"
                ref="typeahead"
                id="typeahead_id"
                placeholder="Enter Keyword to search..."
                class="form-control"
                :items="search.data"
                :itemProjection="
						(item) => {
							return `${item.display_name}`;
						}
					"
                :minInputLength="0"
                :minItemLength="0"
                @onInput="onSearch"
                @selectItem="onSelect"
                @onBlur="endSearching"
                :disabled="loading"
            >
                <template #list-item-text="slot">
                    <span v-html="slot.boldMatchText(slot.itemProjection(slot.item))"></span>
                </template>
            </vue3-simple-typeahead>
        </div>
    </div>
    <div v-else>
        <span>{{ selectedUserDisplayName ?? '-' }}</span>
        <div v-if="date">
            <small class="text-muted">
                <i class="mdi mdi-checkbox-marked-circle text-success"></i>
                {{date}}
            </small>
        </div>
        <br />
    </div>

    <ConfirmationModal
        :id="'confirmation-modal-' + id"
        :show="showModal"
        @confirm="onConfirmPasswordModal"
        @close="onClosePasswordModal"
    >
        <template v-slot:body>
            <span v-if="type === 'remisier'">
                Are you sure to assign remisier <strong>{{selectedUser?.display_name}}</strong> to this application?
            </span>
            <span v-else-if="type === 'dataVerifiedBy'">
                Are you sure to assign <strong>{{selectedUser?.display_name}}</strong> to this application for data verification?
            </span>
            <span v-else-if="type === 'dataApprovedBy'">
                Are you sure to assign <strong>{{selectedUser?.display_name}}</strong> to this application for data approval?
            </span>

        </template>
    </ConfirmationModal>
</template>

<script>
import { useToast } from "vue-toastification";
import _ from "lodash";
import { parseErrorMessage } from "../../helpers/errorHandler";
import ConfirmationModal from "../ConfirmationModal.vue";

export default {
    name: "AccountRegistrationUserSelector",
    components: {
        ConfirmationModal
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        ownerId: [String, Number],
        isSelectable: Boolean,
        displayName: String,
        excludeIds: {
            type: Array,
            default: []
        },
        departmentId: Number,
        positionOrderFrom: Number,
        date: String,
        branchId: Number,
        refreshAfterSave: Boolean,
    },
    data() {
        return {
            loading: false,
            selectedOwnerId: this.ownerId,
            selectedUserDisplayName: this.displayName,
            currentPassword: null,
            selectedUser: null,
            showSelector: false,
            showModal: false,
            search: {
                keyword: null,
                data: [],
            },
            debounce: null
        };
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    computed: {
        getOwnerType() {
            switch (this.type) {
                case 'dataVerifiedBy':
                case 'dataApprovedBy':
                    return 'user';
                default :
                    return this.type;
            }
        },
        getSubmitUrl() {
            switch (this.type) {
                case 'remisier':
                    return `/api/account-registration/${this.id}/remisier`
                case 'dataVerifiedBy':
                    return `/api/account-registration/${this.id}/data-verifier`
                case 'dataApprovedBy':
                    return `/api/account-registration/${this.id}/data-approver`
                default :
                    return null;
            }
        },
        getSubmitData() {
            let data = {};
            switch (this.type) {
                case 'remisier':
                    data.code = this.selectedUser.code;
                    break;
                case 'dataVerifiedBy':
                case 'dataApprovedBy':
                    data.id = this.selectedUser.id;
                    break;
            }

            if (!data) {
                return;
            }

            if (this.currentPassword) {
                data['current_password'] = this.currentPassword;
            }

            return data;
        }
    },
    methods: {
        getOptions() {
            this.search.data = [];
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                axios.post(`/api/${this.getOwnerType}`, {
                    'keyword': this.search.keyword,
                    'id_not': this.excludeIds?.length > 0 ? this.excludeIds : null,
                    'department_id': this.departmentId,
                    'position_order_from': this.positionOrderFrom ?? null,
                    'branch_id': this.branchId,
                    'type': this.type
                }).then((res) => {
                    this.search.data = res?.data?.data;
                }).catch((error) => {
                    this.toast.error(parseErrorMessage(error))
                })
            }, 300);
        },
        startSearching() {
            this.showSelector = true;
            this.$nextTick(() => {
                this.$refs.typeahead.focusInput();
                this.getOptions();
            });
        },
        endSearching() {
            this.showSelector = false;
        },
        onSearch(e) {
            this.search.keyword = e.input;
            this.selectedOwner = null;
            this.getOptions();
        },
        onSelect(item) {
            if (item.id === this.selectedOwnerId) {
                return;
            }

            this.selectedUser = item;

            if (!this.selectedOwnerId) {
                this.submit();
            } else {
                this.showModal = true;
            }
        },
        onConfirmPasswordModal(currentPassword) {
            this.showModal = false;
            this.currentPassword = currentPassword;
            this.submit();
        },
        onClosePasswordModal() {
            this.showModal = false;
        },
        submit() {
            if (!this.selectedUser) {
                return;
            }

            if (this.selectedOwnerId && !this.currentPassword) {
                return;
            }

            this.loading = true;

            const url = this.getSubmitUrl;
            const data = this.getSubmitData;

            axios.post(url, data).then(({ data }) => {
                this.selectedOwnerId = data?.result?.id;
                this.selectedUserDisplayName = data?.result?.display_name;
                this.toast.success('Assigned successfully');

                if (this.refreshAfterSave) {
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                }
            }).catch(({ response }) => {
                const error = response?.data?.message || Object.values(response?.data)[0][0];
                this.toast.error(error ?? 'There was an error. Please try again.')
            }).finally(() => {
                this.endSearching();
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
