<template>
    <div class="row align-items-center mt-3 mb-1" v-if="meta.total >= 1 && meta.last_page !== 1">
        <div class="col-sm-12 col-md-5">
            <div>Showing {{ meta?.from }} to {{ meta?.to }} of {{ meta?.total }} entries</div>
        </div>
        <div class="col-sm-12 col-md-7 pull-right right">
            <div class="paging_simple_numbers">
                <ul class="pagination pagination-rounded mb-0 justify-content-end">
                    <li v-for="(link, idx) in meta?.links"
                        v-bind:class="{
                            'paginate_button page-item': link.label !== '...',
                            disabled: (idx === 0 && meta.current_page <= 1) || (idx+1 === meta.links.length && meta.last_page === meta.current_page),
                            previous: idx === 0,
                            next: idx+1 === meta.links.length,
                            active: meta.current_page === Number(link.label)
                        }"
                    >

                        <a v-if="link.label !== '...'" href="javascript:void(0);" class="page-link" @click="onPageClick(link)">
                            <i v-if="idx === 0" class="fa-solid fa-angle-left"></i>
                            <i v-else-if="idx+1 === meta.links.length" class="fa-solid fa-angle-right"></i>
                            <span v-else>{{ link.label }}</span>
                        </a>
                        <span v-else>{{ link.label}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TablePagination",
    props: {
        meta: Object,
        links: Object,
        pageChanged: Function,
    },
    methods: {
        onPageClick(data) {
            let page;
            if (data.label === 'Previous') {
                page = this.meta.current_page - 1;
            } else if (data.label === "Next") {
                page = this.meta.current_page + 1;
            } else if (data.label === "...") {
                return;
            } else {
                page = Number(data.label);
            }
            this.$emit('page-changed', page)
        },
    }
}
</script>

<style scoped>

</style>
