import {flatMap, isString} from "lodash";

function parseErrorMessage(error) {
    if (isString(error)) {
        return error;
    }

    let message = error?.response?.data;
    if (message?.message) {
        message = message?.message;
    } else {
        message = flatMap(error).toString();
    }

    return message;
}

export {
    parseErrorMessage
}
