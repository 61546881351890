<template>
    <div class="card">
        <div class="card-body">
             <!-- Left sidebar -->
            <div class="page-aside-left p-0">
                <div class="btn-group d-block mb-2">
                    <a href="/document/create" class="btn btn-primary w-100"><i class="mdi mdi-plus"></i> New Uploads </a>
                    <button @click="onClearFilter" class="btn btn-outline-secondary w-100 mt-1" ><i class="mdi mdi-lock-reset"></i> Clear Filters </button>
                </div>

                <h4 class="text-primary mt-3">Filters</h4>
                <div class="email-menu-list">
                    <div class="mb-2">
                        <label class="form-label me-2">Owner Type</label>
                        <select2 v-model="form.owner_type_code"
                                 @select="onSearch()"
                                 :options="ownerTypes"
                                 :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: true }"
                        />
                    </div>

                    <div class="mb-2">
                        <label class="form-label me-2">File Type</label>
                        <select2 v-model="form.file_type_id"
                                 @select="onSearch()"
                                 :options="fileTypes"
                                 :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: true }"
                        />
                    </div>
                    <div class="mb-2">
                        <label class="form-label me-2">File Extension</label>
                        <select2 v-model="form.extension"
                                 @select="onSearch()"
                                 :options="extensions"
                                 :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: true }"
                        />
                    </div>
                    <div class="mb-2" v-if="isBranchOptionVisible">
                        <label class="form-label me-2">Branches</label>
                        <select2 v-model="form.branch_code"
                                 @select="onSearch()"
                                 :options="branches"
                                 :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: true }"
                        />
                    </div>
                </div>
            </div>
            <!-- end Left sidebar -->

            <!-- Right sidebar -->
            <div class="page-aside-right">

                <div class="d-flex justify-content-between align-items-center">
                    <div class="app-search w-100">
                        <div class="mb-2 position-relative">
                            <input v-model="form.search" @input="onSearch()" type="text" class="form-control" placeholder="Search by File Name/Owner Name/Code/CDS...">
                            <span class="mdi mdi-magnify search-icon"></span>
                            <span class="mdi mdi-close-circle-outline clear-icon" @click="handleClearSearch"></span>
                        </div>
                    </div>
                </div>

                <div class="mt-3">
                    <div class="table-responsive position-relative">
                        <!-- Start Loading -->
                        <div v-if="loading" class="position-absolute w-100 justify-content-center d-flex align-items-center h-100 bg-light bg-opacity-50">
                            <div class="spinner-border" role="status"></div>
                            <span class="ms-1">Loading...</span>
                        </div>
                        <!-- End Loading -->

                        <OffCanvas  v-for="d in data"
                                    :data="{
                                        ...d.master,
                                        document: {
                                            id: d.id,
                                        }
                                    }"
                                    :owner="d.owner"
                                    :permissions="d.permissions"
                                    :hide-replace="true"
                                    :hide-update="true"
                                    @file-deleted="handleDeletedDocument"
                                    @file-replaced="handleReplacedDocument"
                        />

                        <table class="table table-hover table-sm table-centered mb-0 text-nowrap document-list-table border">
                            <thead class="table-primary">
                            <tr>
                                <th>File</th>
                                <th>Name</th>
                                <th class="text-center">Version</th>
                                <th class="text-center">Type</th>
                                <th class="text-center">Owner</th>
                                <th class="text-center">Last Modified</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(d, index) in data" class="cursor-pointer" data-bs-toggle="offcanvas"
                                :data-bs-target="'#off-canvas-' + d.master.id"
                                aria-controls="offcanvasRight">
                                <td>
                                    {{ d.file_type.name }}
                                </td>
                                <td>
                                    <div class="d-flex gap-1 align-items-center">
                                        <div class="avatar-sm">
                                            <div v-if="d.master.is_image"
                                                 :style="{ 'background-image': 'url(' + d.master.src_content + ')'}"
                                                 class="avatar-title bg-secondary rounded thumbnail-background-image">
                                            </div>
                                            <span v-else class="avatar-title bg-secondary rounded" >{{ d.master.extension }}</span>
                                        </div>
                                        <span >{{ d.master.export_file_name }}</span>
                                    </div>
                                </td>
                                <td class="text-center">{{ d.version }}</td>
                                <td class="text-center">{{ toTitleCase(d.owner.type) }}</td>
                                <td class="text-center">{{ d.owner.name.trim() }}</td>
                                <td class="text-center">{{ d.updated_at }}</td>
                            </tr>
                                <tr v-if="data.length <= 0">
                                    <td colspan="8">There is no file available to view.</td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- start pagination -->
                        <TablePagination v-if="pagination" :meta="pagination?.meta" :links="pagination?.links"
                                         @page-changed="onPaginationChanged"/>
                        <!-- end pagination -->

                    </div>

                </div> <!-- end-->

            </div>
            <!-- end Right sidebar-->
        </div>
    </div>
</template>

<script>
import { toTitleCase } from "../../helpers/formatter";
import { debounce, isEmpty } from "lodash";
import select2 from "../Select2.vue";
import { parseErrorMessage } from "../../helpers/errorHandler";
import OffCanvas from "../DocumentUpload/components/offcanvas.vue";
import { useToast } from "vue-toastification";
import TablePagination from "../TablePagination.vue";

export default {
    name: "DocumentSearchIndex",
    props: {
        defaultOwnerId: String,
        defaultOwnerType: String,
        defaultOwnerCode: String,
        ownerTypes: Array,
        fileTypes: Array,
        extensions: Array,
        branches: Array,
        isBranchOptionVisible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        select2,
        OffCanvas,
        TablePagination,
    },
    data() {
        return {
            form: {
                search: "",
                owner_type_code: [],
                file_type_id: [],
                extension: [],
                branch_code: [],
                owner_code: null,
                sort: "updated_at",
                direction: "desc"
            },
            loading: false,
            data: [],
            pagination: null,
        };
    },
    setup(props) {
        const toast = useToast();

        return {
            toast
        }
    },
    mounted() {
        this.onSearch();
    },
    created() {
        if (!isEmpty(this.defaultOwnerType)) {
            this.form.owner_type_code.push(this.defaultOwnerType);
        }

        if (!isEmpty(this.defaultOwnerCode)) {
            this.form.search = this.defaultOwnerCode;
            this.form.owner_code = this.defaultOwnerCode;
        }
    },
    methods: {
        onClearFilter() {
            this.form = {
                search: null,
                owner_type_code: [],
                file_type_id: [],
                extension: [],
                branch_code: [],
                owner_code: null,
                sort: "updated_at",
                direction: "desc"
            };
            this.onSearch();
        },
        onPaginationChanged(page) {
            this.onSearch(page);
        },
        onSearch: debounce(function (page = 1) {
            this.loading = true;
            this.pagination = null;
            axios.get(`/api/document/`, {
                params: {
                    ...this.form,
                    page,
                },
            })
                .then((res) => {
                    this.data = res?.data?.data;
                    this.pagination = {
                        meta: res?.data?.meta,
                        links: res?.data?.links,
                    };
                })
                .catch((error) => {
                    this.toast.error(parseErrorMessage(error))
                })
                .then(() => {
                    this.loading = false;
                })
        }, 500),
        handleClearSearch(){
            this.form.search="";
            this.form.owner_code=null;
            this.onSearch();
        },
        handleDeletedDocument(value) {
            this.onSearch();
            this.toast.success('Document deleted successfully.');
        },
        handleReplacedDocument(value) {
            this.onSearch();
            this.toast.success('Document replaced successfully.');
        },
        toTitleCase,
    }
}
</script>

<style scoped>

</style>
