<template>
    <!-- start Video Modal -->
    <div v-if="videoVisibleRef" @click="onVideoHide" class="video-modal-container">
        <div @click.stop>
            <video :key="videoSrc" controls>
                <source :src="videoSrc" type="video/mp4">
            </video>
        </div>
    </div>
    <!-- end Video Modal -->

    <div class="offcanvas offcanvas-end" tabindex="-1" :id="'off-canvas-' + data.id" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel"></h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="d-flex flex-column gap-2" style="height: 88vh;">
                <div class="d-flex gap-2">
                    <div class="avatar-md">
                        <vue-easy-lightbox
                            moveDisabled
                            :visible="visibleRef"
                            :imgs="imgsRef"
                            :index="indexRef"
                            @hide="onHide"
                        ></vue-easy-lightbox>
                        <div v-if="data.is_image"
                             :style="{ 'background-image': 'url(' + data.src_content + ')'}"
                             class="avatar-title bg-secondary rounded cursor-pointer thumbnail-background-image"
                             @click="showSingle">
                        </div>
                        <span v-else class="avatar-title bg-secondary rounded">{{ data.extension }}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="header-title" style="font-weight: bold">{{ data.export_file_name }}</span>
                        <span class="text-muted" style="font-size: 0.8em">{{ data.short_file_name }}</span>
                        <span>{{ data.extension.toUpperCase() }} - {{ data.file_size }}</span>
                    </div>
                </div>

                <div class="d-flex flex-column gap-2">
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Version</div>
                        <div style="flex: 1; text-align: right">
                            <a :href="'/document/' + data.document.id + '/history'" target="_blank">
                                <i class="fa-solid fa-arrow-up-right-from-square"></i> {{ data.version }}
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Mime</div>
                        <div style="flex: 1; text-align: right">{{ data.mime_type }}</div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom" v-if="['TR'].includes(fileType?.bursa_document_type)">
                        <div style="flex: 1">{{ data.document?.is_required_new_cds.should_fill ? 'New Transferee\'s CDS Acc Number' : (data.document?.is_required_new_registration.should_fill ? 'New Registration Number' : '?') }}</div>
                        <div style="flex: 1; text-align: right">
                            <a href="javascript:void(0);" @click="onClickUpdateCdsNumber">
                                <i class="fa-solid fa-circle-exclamation text-danger" v-if="data?.document?.is_required_new_cds_number"></i>
                                {{ data.document?.attributes?.cds_number || 'Update Now' }}
                            </a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom mt-1" v-for="(d, index) in data?.document?.is_required_tick_cds_update_section">
                        <div style="flex: 1">{{ d.label }}</div>
                        <div style="flex: 1; text-align: right">
                            <a v-if="d.should_fill" class="text-end" href="javascript:void(0);" @click="onClickUpdateBursaExportAppendFile" style="text-transform: capitalize">
                                <i class="fa-solid fa-circle-exclamation" :class="d.is_required ? '' : 'd-none'"></i>
                                {{ d.value || 'Update' }}
                            </a>
                            <span v-else>True</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Created</div>
                        <div style="flex: 1; text-align: right">{{ data.created_at }}</div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Created By</div>
                        <div style="flex: 1; text-align: right">{{ data.created_by }}</div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Updated</div>
                        <div style="flex: 1; text-align: right">{{ data.updated_at }}</div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom">
                        <div style="flex: 1">Updated By</div>
                        <div style="flex: 1; text-align: right">{{ data.updated_by }}</div>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom" v-if="owner">
                        <div style="flex: 1">Owner</div>
                        <a :href="`/document/create?owner_id=${owner.id}&owner_type=${owner.type}`" target="_blank"
                           style="flex: 1; text-align: right">
                            <i class="fa-solid fa-arrow-up-right-from-square"></i> {{ owner.name }} ({{ owner.identity }})
                        </a>
                    </div>
                    <div class="d-flex justify-content-between py-1 border-bottom" v-if="permissions.is_can_view_operation_log">
                        <div />
                        <div>
                            <a :href="'/document/operation-log?owner_type=document&owner_id=' + data.document.id" target="_blank">
                                <i class="fa-solid fa-arrow-up-right-from-square"></i> Operation Log
                            </a>
                        </div>
                    </div>
                </div>

                <div class="d-flex gap-2 mt-2">
                   <button v-if="permissions?.is_can_delete && !hideDelete" class="btn btn-outline-danger" @click="onClickDelete" :disabled="this.deleteLoading">
                       <i class="fa-solid fa-trash"></i> {{ deleteLoading ? "Loading..." : "Delete" }}
                   </button>
                   <a v-if="permissions?.is_can_download" class="btn btn-outline-primary ms-auto" :href="data.download_url" :disabled="this.deleteLoading">
                       <i class="fa-solid fa-circle-down"></i> Download
                   </a>
                    <button class="btn btn-outline-primary" v-if="data.is_image" @click="showSingle" :disabled="this.deleteLoading">
                        <i class="fa-solid fa-mountain-sun"></i> Preview
                    </button>
                    <a class="btn btn-outline-primary" v-if="data.is_pdf" :href="data.preview_src_path" target="_blank" :disabled="this.deleteLoading">
                        <i class="fa-solid fa-mountain-sun"></i> Preview
                    </a>
                    <a class="btn btn-outline-primary" v-if="data.is_video" @click="showVideo" :disabled="this.deleteLoading">
                        <i class="fa-solid fa-mountain-sun"></i> Preview
                    </a>
                </div>

                <div :id="'offcanvas-previews-container-' + data.id" class="mt-auto" v-if="permissions.is_can_create && !hideReplace">
                    <Dropzone :dropzone-id="'offcanvas-dropzone-' + data.id"
                              :previews-container-id="'offcanvas-previews-container-' + data.id"
                              :preview-template-id="'offcanvas-preview-template-' + data.id"
                              :max-files="1"
                              :is-reached-limit="false"
                              :max-file-size="data.max_file_size_in_mb"
                              :upload-document-url="'/api/document/replace/' + data.document.id"
                              :placeholder="'Drop files here or click to replace.'"
                              :skip-confirmation="true"
                              @file-uploaded="handleDocumentUploaded"
                    />

                    <!-- start file preview template -->
                    <div class="d-none" :id="'offcanvas-preview-template-' + data.id">
                        <div class="card mt-2 mb-1 shadow-none border">
                            <div class="d-flex flex-column cursor-pointer p-1 position-relative">
                                <a href="javascript:void(0);"
                                   data-bs-toggle="tooltip"
                                   data-dz-remove=""
                                   title="Delete" class="btn btn-link text-danger btn-lg position-absolute top-0 end-0">
                                    <i class="fa-solid fa-xmark"></i>
                                </a>
                                <div class="d-flex gap-1">
                                    <div class="avatar-md">
                                        <img data-dz-thumbnail :src="fileIconImg" style="object-fit: contain;object-position: center;padding: 5px;" class="avatar-md rounded bg-light" alt="">
                                    </div>
                                    <div class="">
                                        <a href="javascript:void(0);" class="text-muted fw-bold text-break" data-dz-name></a>
                                        <p class="mb-0" data-dz-size></p>
                                    </div>
                                </div>
                                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                                <div class="dz-error-message text-danger"><span data-dz-errormessage></span></div>
                            </div>
                            <div class="modal fade preview-modal" tabindex="-1" role="dialog">
                                <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header modal-colored-header bg-dark">
                                            <h5 class="modal-title">Preview</h5>
                                        </div>
                                        <div class="modal-body" style="padding: 0">
                                            <div class="upload-iframe-container"></div>
                                        </div>
                                        <div class="modal-footer" style="background: transparent; position: absolute; bottom: 0; right: 20px;">
                                            <button type="button" class="btn btn-danger btn-lg my-cancel-button" data-bs-dismiss="modal">Reject</button>
                                            <button type="button" class="btn btn-success btn-lg my-confirm-button" data-bs-dismiss="modal">Verified</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end file preview template -->
                </div>

                <!-- start delete confirmation modal -->
                <div :id="'off-canvas-delete-confirmation-' + data.id" class="modal fade offcanvas-modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header modal-colored-header bg-danger">
                                <h4 class="modal-title" id="confirmation-modalLabel">Confirmation</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                            </div>
                            <div class="modal-body">
                                Are you sure you want to delete this document?
                                <br />
                                Please provide the reason for your entry below.
                                <br />
                                <b v-if="data?.document?.owner?.siblingCount > 0 || owner?.siblingCount > 0" class="text-danger">Please be cautious, This document is shared by Multiple Clients.</b>
                                <br v-if="data?.document?.owner?.siblingCount > 0 || owner?.siblingCount > 0" />
                                <label :for="'reason-' + data.id" class="form-label">Reason</label>
                                <textarea rows="3" :id="'reason-' + data.id" class="form-control required" :class="deleteConfirmation.isError ? 'is-invalid' : '' " autofocus v-model="deleteConfirmation.reason" />
                                <span class="invalid-feedback" role="alert"><strong>{{ deleteConfirmation.message || "Please enter reason" }}</strong></span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-danger" @click="onDelete">Yes, confirm</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div><!-- /.modal -->
                <!-- end delete confirmation modal -->

                <!-- start update new cds / registration number modal -->
                <div :id="'off-canvas-update-cds-number-modal-' + data.id" class="modal fade offcanvas-modal" v-if="!hideUpdate" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header modal-colored-header bg-dark">
                                <h4 class="modal-title" id="cds-number-label">Update Required Information</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                            </div>
                            <div class="modal-body">
                                Enter {{ data.document?.is_required_new_cds.should_fill ? 'New Transferee’s CDS Account Number (last 9 digits)' : (data.document?.is_required_new_registration.should_fill ? 'New Registration Number' : '?') }}
                                <br />
                                <input type="text" :id="'registration-' + data.id" class="form-control required" :class="updateCdsNumber.isError ? 'is-invalid' : '' " autofocus v-model="updateCdsNumber.cds_number" />
                                <span class="invalid-feedback" :class="updateCdsNumber.isError ? 'd-block' : '' "  role="alert">
                                    <strong>
                                    {{ updateCdsNumber.message || ("Please enter " + (data.document?.is_required_new_cds.should_fill ? 'New Transferee’s CDS Account Number (last 9 digits)' : (data.document?.is_required_new_registration.should_fill ? 'New Registration Number' : '?')) ) }}
                                    </strong>
                                </span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-dark" @click="onUpdateCdsNumber">Submit</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div><!-- /.modal -->
                <!-- end update new cds / registration number modal -->

                <!-- start update Bursa Append Extra File Modal -->
                <div :id="'off-canvas-update-bursa-append-file-modal-' + data.id" class="modal fade offcanvas-modal" v-if="!hideUpdate" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header modal-colored-header bg-dark">
                                <h4 class="modal-title" id="cds-number-label">Update Required Information</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-check form-switch" v-for="d in updateBursaExportAppendFileSetting.formData">
                                    <label class="form-check-label" :for="'append-file-' + data.id + '-' + d.key">
                                        {{ d.label }}
                                    </label>
                                    <input type="checkbox" class="form-check-input required" :class="updateBursaExportAppendFileSetting.isError ? 'is-invalid' : '' " v-model="d.value" name="d.key" :id="'append-file-' + data.id + '-' + d.key">
                                </div>
                                <span class="invalid-feedback" :class="updateBursaExportAppendFileSetting.isError ? 'd-block' : '' " role="alert"><strong>{{ updateBursaExportAppendFileSetting.message }}</strong></span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-dark" @click="onUpdateBursaExportAppendFile">Submit</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div><!-- /.modal -->
                <!-- end update new cds / registration number modal -->
            </div>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
// you need to import css yourself
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import { useToast } from "vue-toastification";
import { ref } from 'vue';
import Dropzone from "./dropzone.vue";
import { parseErrorMessage } from "../../../helpers/errorHandler";

export default {
    name: "DocumentUploadOffCanvas",
    props: {
        dropzoneId: String,
        data: Object,
        owner: Object,
        permissions: Object,
        fileType: Object,
        onDeleted: Function,
        hideDelete: {
            type: Boolean,
            default: false,
        },
        hideReplace: {
            type: Boolean,
            default: false,
        },
        hideUpdate: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        VueEasyLightbox,
        Dropzone,
    },
    data() {
        let updateBursaExportAppendFileSettingForm = [];
        this.data.document?.is_required_tick_cds_update_section?.forEach(d => {
            if (d.should_fill) {
                updateBursaExportAppendFileSettingForm.push({
                    label: d.label,
                    key: d.file_type.id,
                    value: d.value ? d.value : false
                })
            }
        });
        return {
            fileIconImg: '/images/elements/file-icon.png',
            loading: false,
            deleteLoading: false,
            deleteConfirmation: {
                reason: "",
                isError: false,
                message: "",
            },
            updateCdsNumber: {
                cds_number: "",
                isError: false,
                message: "",
                loading: false,
            },
            updateBursaExportAppendFileSetting: {
                formData: updateBursaExportAppendFileSettingForm,
                isError: false,
                message: "",
                loading: false,
            },
        }
    },
    methods: {
        onClickDelete() {
            const deleteModal = new bootstrap.Modal(document.getElementById('off-canvas-delete-confirmation-' + this.data.id));
            deleteModal.show();
        },
        onDelete() {
            if (!this.deleteConfirmation.reason) {
                this.deleteConfirmation.isError = true;
                return;
            }

            this.deleteLoading = true;
            axios.delete(`/api/document/${this.data.document.id}`, {
                data: {
                    reason: this.deleteConfirmation.reason,
                }
            })
                .then((res) => {
                    const myModalEl = document.getElementById('off-canvas-delete-confirmation-' + this.data.id);
                    const modal = bootstrap.Modal.getInstance(myModalEl)
                    modal.hide();

                    const myOffcanvas = document.getElementById('off-canvas-' + this.data.id);
                    const bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
                    bsOffcanvas.hide();

                    this.deleteConfirmation = {
                        reason: "",
                        isError: false,
                    };

                    this.$emit('fileDeleted')
                })
                .catch((error) => {
                    this.deleteConfirmation.message = parseErrorMessage(error);
                })
                .then(() => {
                    this.deleteLoading = false;
                })
        },
        onClickUpdateBursaExportAppendFile() {
            const modal = new bootstrap.Modal(document.getElementById('off-canvas-update-bursa-append-file-modal-' + this.data.id));
            modal.show();
        },
        onClickUpdateCdsNumber() {
            const modal = new bootstrap.Modal(document.getElementById('off-canvas-update-cds-number-modal-' + this.data.id));
            modal.show();
        },
        onUpdateCdsNumber() {
            if (!this.updateCdsNumber.cds_number) {
                this.updateCdsNumber.isError = true;
                return;
            }
            this.updateCdsNumber.loading = true;
            axios.put(`/api/document/${this.data.document.id}`, {
                attributes: {
                    cds_number: this.updateCdsNumber.cds_number,
                }
            })
                .then((res) => {
                    const myModalEl = document.getElementById('off-canvas-update-cds-number-modal-' + this.data.id);
                    const modal = bootstrap.Modal.getInstance(myModalEl);
                    modal.hide();

                    const myOffcanvas = document.getElementById('off-canvas-' + this.data.id);
                    const bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
                    bsOffcanvas.hide();

                    if (!_.isEmpty(res.data.warning_message)) {
                        alert(res.data.warning_message);
                    }

                    this.updateCdsNumber = {
                        ...this.updateCdsNumber,
                        cds_number: "",
                        isError: false,
                    };

                    this.$emit('fileUpdated')

                })
                .catch((error) => {
                    this.updateCdsNumber.message = parseErrorMessage(error?.response?.data);
                    this.updateCdsNumber.isError = true;
                })
                .then(() => {
                    this.updateCdsNumber.loading = false;
                })
        },
        onUpdateBursaExportAppendFile() {
            this.updateBursaExportAppendFileSetting.loading = true;
            axios.put(`/api/document/${this.data.document.id}`, {
                attributes: {
                    append_files: this.updateBursaExportAppendFileSetting.formData
                }
            })
                .then((res) => {
                    const myModalEl = document.getElementById('off-canvas-update-bursa-append-file-modal-' + this.data.id);
                    const modal = bootstrap.Modal.getInstance(myModalEl);
                    modal.hide();

                    const myOffcanvas = document.getElementById('off-canvas-' + this.data.id);
                    const bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
                    bsOffcanvas.hide();

                    this.updateBursaExportAppendFileSetting = {
                        ...this.updateBursaExportAppendFileSetting,
                        formData: [],
                        isError: false,
                        message: ""
                    };

                    this.$emit('fileUpdated')

                })
                .catch((error) => {
                    this.updateBursaExportAppendFileSetting.message = parseErrorMessage(error?.response?.data);
                    this.updateBursaExportAppendFileSetting.isError = true;
                })
                .then(() => {
                    this.updateBursaExportAppendFileSetting.loading = false;
                })
        },
        handleDocumentUploaded(value) {
            this.$emit('fileReplaced', value);
        },
    },
    emits: {
        fileReplaced: null,
        fileUpdated: null,
        fileDeleted: null,
    },
    setup(props) {
        const toast = useToast();
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        const videoVisibleRef = ref(false);
        const videoSrc = ref(null);
        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = () => {
            imgsRef.value = props.data.src_content;
            onShow();
        }
        const onHide = () => (visibleRef.value = false)

        const showVideo = () => {
            videoSrc.value = props.data.src_content;
            videoVisibleRef.value = true;
        };
        const onVideoHide = () => (videoVisibleRef.value = false);

        return {
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            onHide,
            toast,
            videoVisibleRef,
            videoSrc,
            showVideo,
            onVideoHide
        }
    }
}
</script>

<style scoped>
.video-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}

</style>
