import { isEmpty, startCase, toLower } from "lodash";

const makeEllipsis = (text, maxLength) => {
    if (isEmpty(text) || maxLength <= 0) {
        return '';
    }

    let partition = Math.floor(maxLength/2);

    if (text.length > maxLength) {
        return text.substring(0, partition) + '...' + text.substring(text.length - partition, text.length);
    }

    return text;
}

const toTitleCase = (text) => {
    return startCase(toLower(text));
}

export {
    makeEllipsis,
    toTitleCase,
}
