<template>
    <div class="card">
        <!-- Start Loading -->
        <div v-if="loading" class="d-flex justify-content-center align-items-center">
            <div class="spinner-border" role="status"></div>
            <span class="ms-1">Loading...</span>
        </div>
        <!-- End Loading -->

        <CreateModal :visible="isCreateModalVisible"
                     :branch_classification="createModalBranchClassification"
                     @closeModal="isCreateModalVisible = false"
                     :departmentOptions="departmentOptions"
                     :positionOptions="positionOptions"
                     @created="get"
        />
        <div class="table-responsive scroll-viewport">
            <table v-for="(d) in items" class="table table-hover table-sm table-centered mb-0 text-nowrap document-list-table border mb-3">
                <thead class="table-primary">
                <tr>
                    <th colspan="5">Classification - {{ d.branch_classification.name }}</th>
                    <th class="text-end"><button v-show="updatable" class="btn btn-sm btn-primary" @click="showCreateModal(d.branch_classification)"><i class="fa-solid fa-plus"></i> Add</button></th>
                </tr>
                <tr class="bg-white text-center">
                    <th width="5%">#</th>
                    <th width="10%">Min Amount</th>
                    <th width="10%">Max Amount</th>
                    <th width="10%">Number Of Approval</th>
                    <th width="45%" class="text-start">Settings</th>
                    <th width="10%" class="text-end">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="d.items.length" v-for="(d1, index) in d.items" class="text-center">
                    <td>{{ index+1 }}</td>
                    <td>{{ $filters.numberToText(d1.min_amount) }}</td>
                    <td>{{ $filters.numberToText(d1.max_amount)  }}</td>
                    <td>{{ d1.number_approves }}</td>
                    <td class="text-start text-wrap">
                        <span v-for="(d2, index) in d1.settings" :key="index">
                            {{ d2.description }}
                            <span v-if="d2.is_hq_only" class="text-muted">(HQ)</span>
                            <span v-if="d2.is_first_approval_only" class="text-muted">(First Approval)</span>
                            <span v-if="index < d1.settings.length - 1"> || </span>
                        </span>
                        <div v-if="d1.is_must_different_department">
                            <span class="text-muted">**Must different department</span>
                        </div>
                    </td>
                    <td class="text-end">
                        <a class="text-danger cursor-pointer" v-show="updatable" @click="remove(d1.id)">Delete</a>
                    </td>
                </tr>
                <tr v-else>
                    <td colspan="6" class="text-center">No available data</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="loading" class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import { useToast } from "vue-toastification";
import CreateModal from "./create.vue";

export default {
    name: "CcdWorkflowSetting",
    props: {
        updatable: {
            type: Boolean,
            default: false,
        },
        departmentOptions: Array,
        positionOptions: Array,
    },
    components: {
        CreateModal,
    },
    data() {
        return {
            loading: false,
            items: [],
            isCreateModalVisible: false,
            createModalBranchClassification: null,
        }
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    created() {
       this.get();
    },
    methods: {
        get() {
            axios.get(`/api/ccd-workflow`)
                .then(({ data }) => {
                    this.items = data;
                }).catch(() => {
                this.toast.error("There was an error get settings. Please try again.");
            })
                .finally(() => {
                    $(document).ready( function() {
                        $("#ccdLoadingOverlap").css("display", "none");
                    });
                });
        },
        remove(id) {
            if (!this.updatable) {
                return;
            }

            if (!confirm("Are you sure to remove this setting?")) {
                return;
            }

            this.loading = true;

            axios.delete(`/api/ccd-workflow/${id}/`)
                .then(({ data }) => {
               this.get();
                this.toast.success("Setting has been removed.");
            }).catch(err => {
                this.toast.error("There was an error to delete the setting. Please try again.");
            }).finally(() => {
                this.loading = false;
            });
        },
        showCreateModal(branchClassification) {
            this.isCreateModalVisible = true;
            this.createModalBranchClassification = branchClassification;
        },
    }
}
</script>

<style scoped>
</style>
