<template>
    <ol class="list-group list-group-flush list-group-numbered mt-n2">
        <li v-for="document in documents" class="list-group-item">
            <a v-if="isPdf(document.src_path)" target="_blank" :href="getPreviewUrl(document.file_type_id)">
                {{ document.file_type_name }}
                <a style="display: inline-flex; align-items: center" target="_blank" v-on:click.stop :href="getDownloadUrl(document.file_type_id)">
                    <i class="mdi mdi-download"></i>
                </a>
            </a>
            <a v-else-if="isImage(document.src_path)" @click="previewImage(document.file_type_id)" href="javascript:void(0);">
                {{ document.file_type_name }}
                <a style="display: inline-flex; align-items: center" target="_blank" v-on:click.stop :href="getDownloadUrl(document.file_type_id)">
                    <i class="mdi mdi-download"></i>
                </a>
            </a>
            <a v-else target="_blank" :href="getDownloadUrl(document.file_type_id)">
                {{ document.file_type_name }} <i class="mdi mdi-download"></i>
            </a>
        </li>
    </ol>
    <vue-easy-lightbox
        moveDisabled
        :visible="previewVisible"
        :imgs="imgsSrc"
        :index="index"
        @hide="hidePreview"
    ></vue-easy-lightbox>
</template>

<script>
import { useToast } from "vue-toastification";
import VueEasyLightbox from 'vue-easy-lightbox'
// you need to import css yourself
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'

export default {
    name: "AccountRegistrationDocument",
    props: {
        id: Number,
        documents: Array,
    },
    components: {
        VueEasyLightbox,
    },
    data() {
        return {
            previewVisible: false,
            imgsSrc: [],
            index: 0,
        };
    },
    setup(props) {
        const toast = useToast();

        return {
            toast
        }
    },
    created() {
        $(document).ready( function() {
            $("#documentLoadOverlay").removeClass("d-flex").css("display", "none");
        });
    },
    methods: {
        isPdf(src) {
            return src.endsWith(".pdf");
        },
        isImage(src) {
            return src.endsWith(".jpg") || src.endsWith(".jpeg") || src.endsWith(".png");
        },
        getPreviewUrl(fileTypeId) {
            return `/account-registration/${this.id}/preview/${fileTypeId}`;
        },
        getDownloadUrl(fileTypeId) {
            return `/account-registration/${this.id}/content/${fileTypeId}/attachment`;
        },
        previewImage(fileTypeId) {
            this.imgsSrc = `/account-registration/${this.id}/content/${fileTypeId}`;
            this.previewVisible = true;
        },
        hidePreview() {
            this.previewVisible = false;
        }
    }
}
</script>

<style scoped>

</style>
