<template>
    <div class="pdf-app">
        <div class="top-bar">
            <div class="loading" v-if="isLoading">
                Loading...
            </div>

            <div class="header" v-else>
                <div style="display: flex; align-items: center">
                    <span v-if="showAllPages">{{ pageCount }} page(s)</span>
                    <span v-else>
                        <button class="btn btn-sm btn-secondary me-1" style="padding: 0.1rem 0.6rem;" :disabled="page <= 1" @click="page--">❮</button>
                        {{ page }} / {{ pageCount }}
                        <button class="btn btn-sm btn-secondary ms-1" style="padding: 0.1rem 0.6rem;" :disabled="page >= pageCount" @click="page++">❯</button>
                    </span>
                </div>

                <div style="display: flex; align-items: center">
                    <button class="btn btn-sm btn-secondary me-1" style="padding: 0.1rem 0.6rem;" :disabled="width <= 500 || zoomClicked" @click="handleZoom(-100)"><i class="mdi mdi-minus-circle"></i></button>
                    {{ width ?? '100' }} px
                    <button class="btn btn-sm btn-secondary ms-1" style="padding: 0.1rem 0.6rem;" :disabled="width >= 3000 || zoomClicked" @click="handleZoom(100)"><i class="mdi mdi-plus-circle"></i></button>
                </div>

                <label class="right" style="display: flex; align-items: center">
                    <input v-model="showAllPages" type="checkbox"><span class="ms-1">Show all pages</span>
                </label>
            </div>
        </div>

        <div class="content table-responsive" style="width: 100%; text-align: center">
            <vue-pdf-embed ref="pdfRef" :width="width" :source="src" :page="page" @password-requested="handlePasswordRequest" @rendered="handleDocumentRender" />
        </div>
    </div>
</template>

<script lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    name: 'PdfViewer',
    props: {
        src: String,
    },
    components: { VuePdfEmbed },
    data() {
        return {
            isLoading: true,
            page: null,
            pageCount: 1,
            showAllPages: true,
            zoomClicked: false,
            width: null,
        }
    },
    watch: {
        showAllPages() {
            this.page = this.showAllPages ? null : 1
        },
    },
    methods: {
        handleZoom(value) {
            if (this.zoomClicked) {
                return;
            }
            this.zoomClicked = true;
            this.width = this.width + value;
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount

            if (!this.width) {
                this.width = this.$refs.pdfRef.$el.clientWidth
            }
            this.zoomClicked = false;
        },
        handlePasswordRequest(callback, retry) {
            callback(prompt(retry
                ? 'Enter password again'
                : 'Enter password'
            ))
        },
    }
}
</script>

<style scoped>
.pdf-app {
    margin: 0;
    padding: 0;
    background-color: whitesmoke;
    position: relative;
}

.pdf-app .top-bar {
    background-color: #555;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    color: #ddd;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 51px;
}

.pdf-app .loading {
    text-align: center;
    font-size: 1.2em;
    padding: 10px;
}

.pdf-app .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 18px;
}

.pdf-app .content {
    padding: 51px 16px 20px 16px;
}
</style>
