<template>
    <div class="card">
        <div class="card-header">
            <h5>Comments ({{ count }})</h5>
        </div>
        <div class="card-body">
            <div v-if="count <= 0">
                <p>There is no comment.</p>
            </div>

            <div class="row mt-n2 overflow-scroll" style="max-height: 600px; overflow-x: hidden !important;" ref="comments">
                <div class="col">
                    <div v-for="(item, index) in items"  class="d-flex align-items-start">
                        <div class="d-flex flex-column align-items-start p-1 w-100">
                            <h5 class="mt-0 mb-0 font-14 w-100">
                                {{  item.comment_by_username }}
                                <span class="text-muted font-10">{{ formatDate(item.comment_at) }}</span>
                            </h5>
                            <p class="mt-1 mb-0 text-muted font-14 w-100" style="white-space: pre">
                                {{ item.comment }}
                            </p>
                            <p v-show="updatable && item.is_removable" class="my-0">
                                <a class="font-12" role="button" @click="remove(index)"><i class="far fa-trash-alt"></i> Remove</a>
                            </p>
                            <hr style="margin: 5px 0; height: 3px; background: #eef2f7; width: 90%;"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Start Loading -->
            <div v-if="loading" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
                <span class="ms-1">Loading...</span>
            </div>
            <!-- End Loading -->

            <div v-show="updatable" class="comment-area-box mt-3">
                <textarea rows="5" class="form-control border-bottom-0 resize-none" placeholder="Enter comment here" v-model="newComment" :disabled="loading" style="resize: none"></textarea>
                <div class="p-2 bg-light border border-top-0 text-end">
                    <button class="btn btn-sm btn-success" :disabled="!newComment || loading" @click="create"><i class="uil uil-message me-1"></i>Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
    name: "AccountRegistrationCommentIndex",
    props: {
        id: {
            type: Number,
            required: true,
        },
        updatable: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            count: 0,
            items: [],
            newComment: null,
            errors: [],
        }
    },
    setup(props) {
        const toast = useToast();

        return {
            toast
        }
    },
    created() {
        axios.get(`/api/account-registration/${this.id}/comments`)
            .then(({ data }) => {
            this.items = data.items;
            this.count = data.count;
        }).catch(() => {
            this.toast.error("There was an error adding comment. Please try again.");
        })
            .finally(() => {
            $(document).ready( function() {
                $("#commentLoadOverlay").css("display","none");
            });
        });
    },
    methods: {
        formatDate(value) {
            const date = moment(value);
            return date.format("DD/MM/YYYY hh:mma");
        },
        create () {
            if (!this.updatable) {
                return;
            }

            this.loading = true;
            this.errors = [];

            if (!this.newComment) {
                this.errors = ["Please enter message"];
            }

            axios.post(`/api/account-registration/${this.id}/comment`, {
                comment: this.newComment
            }).then(({ data }) => {
                this.items = data.items;
                this.count = data.count;
                this.toast.success("Comment has been added.");
                this.$nextTick(() => {
                    this.$refs.comments.scrollTo({ top: this.$refs.comments.scrollHeight, left: 0, behavior: "smooth" });
                });
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = err.response.data?.comment;
                } else {
                    this.toast.error("There was an error adding comment. Please try again.");
                }
            }).finally(() => {
                this.newComment = null;
                this.loading = false;
            });
        },
        remove(index) {
            const comment = this.items[index];
            if (!this.updatable || !comment) {
                return;
            }

            if (!comment.is_removable) {
                this.toast.error("You are not allowed to remove this comment");
                return;
            }

            if (!confirm("Are you sure to remove this comment?")) {
                return;
            }

            this.loading = true;

            axios.delete(`/api/account-registration/${this.id}/comment/${index}`)
                .then(({ data }) => {
                this.items = data.items;
                this.count = data.count;
                this.toast.success("Comment has been removed.");
            }).catch(err => {
                err.response.data?.comment?.forEach(error => this.toast.error(error));
            }).finally(() => {
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>
</style>
