<template>
    <div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-2 ">
            <h5 class="mb-2">Documents</h5>

            <div class="position-relative d-flex gap-2">
                <div>
                    <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="archivedView" class="bold-700 text-danger">Archived Documents</span>
                        <span v-else>New Documents</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item cursor-pointer" @click="changeArchiveView(1)">
                            <span v-if="archivedView">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            Archived
                        </a>
                        <a class="dropdown-item cursor-pointer" @click="changeArchiveView(0)">
                            <span v-if="!archivedView">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            New
                        </a>
                    </div>
                </div>

                <div>
                    <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="displayView === DISPLAY_LIST"><i class="fa-solid fa-list"></i></span>
                        <span v-else><i class="fa-solid fa-table-columns"></i></span>
                        View
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item cursor-pointer" @click="changeView(DISPLAY_LIST)">
                            <span v-if="displayView === DISPLAY_LIST">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            As List
                        </a>
                        <a class="dropdown-item cursor-pointer" @click="changeView(DISPLAY_COLUMN)">
                            <span v-if="displayView === DISPLAY_COLUMN">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            As Columns
                        </a>
                    </div>
                </div>

                <div class="input-group">
                    <input type="text" class="form-control" :value="search.keyword" @input="onFilesSearch" placeholder="Search file name...">
                </div>
            </div>
        </div>

        <!-- Start notification -->
        <div v-if="notification" class="alert alert-success alert-dismissible fade show" role="alert">
            {{ notification }}
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <!-- End notification -->


        <!-- Start Loading -->
        <div v-if="loading" class="d-flex justify-content-center align-items-center full-screen-loading">
            <div class="spinner-border" role="status"></div>
            <span class="ms-1">Loading...</span>
        </div>
        <!-- End Loading -->

        <div v-if="getData().length <= 0" class="d-flex justify-content-center m-auto mt-5">
            <p class="mb-0">There is no file type to view.</p>
        </div>

        <!-- Start Loop Update Required Files -->
        <div v-if="getNeedUpdateData().length > 0">
            <h5 class="text-danger mt-0">
                Required Update Fields ({{ getNeedUpdateData().length }})
            </h5>
            <div class="card card-body">
                <div class="row g-2">
                    <div v-for="(file, index) in getNeedUpdateData()" class="col-12 col-md-2">
                        <div class="card h-100 mb-0 border p-1">
                            <div class="d-flex flex-md-column align-items-md-center gap-1 h-100">
                                <div class="avatar-md">
                                    <span class="avatar-title bg-secondary rounded cursor-pointer" data-bs-toggle="offcanvas"
                                          :data-bs-target="'#off-canvas-' + file.id"
                                          aria-controls="offcanvasRight">
                                        {{ file.extension }}
                                    </span>
                                </div>
                                <div class="text-md-center text-sm mt-auto cursor-pointer w-100" data-bs-toggle="offcanvas"
                                     :data-bs-target="'#off-canvas-' + file.id"
                                     aria-controls="offcanvasRight">
                                    <a href="javascript:void(0);" class="text-muted fw-bold">{{ file.export_file_name }}</a>
                                    <i class="fa-solid fa-circle-exclamation text-danger ms-1"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start Loop File Types -->
        <div v-for="d in getData()">
            <div v-for="file in d.files">
                <OffCanvas
                    :data="file"
                    :file-type="d.file_type"
                    :permissions="d.permissions"
                    @file-deleted="handleDeletedDocument"
                    @file-replaced="handleReplacedDocument"
                    @file-updated="handleUpdatedDocument"
                />
            </div>

            <div :key="d.file_type.id + '-' + displayView">
                <!-- Start File Name Row -->
                <h5 class="text-dark mt-0 cursor-pointer" data-bs-toggle="collapse"
                    :href="'#card-' + d.file_type.id"
                    aria-expanded="false"
                    :aria-controls="'card-'+d.file_type.id">
                    <i class="uil uil-angle-down font-18"></i> {{ d.file_type.name }}
                    <span class="text-muted">({{ d.files.length }})</span>
                </h5>
                <!-- End File Name Row -->

                <div class="collapse show" :id="'card-' + d.file_type.id">
                    <div class="card card-body">
                        <Dropzone
                            :key="d.file_type.id + '-' + displayView"
                            :dropzone-id="displayView + '-dropzone-' + d.file_type.id"
                            :previews-container-id="displayView + '-previews-container-' + d.file_type.id"
                            :preview-template-id=" displayView + '-preview-template-' + d.file_type.id"
                            :max-files="d.file_type.maximum_files"
                            :max-file-size="d.file_type.max_file_size_in_mb"
                            :isReachedLimit="d.file_type.maximum_files && d.files.length >= d.file_type.maximum_files"
                            :upload-document-url="'/api/document/' + ownerType + '/' + ownerId + (archivedView ? '?isArchive=1' : '')"
                            @file-uploaded="handleCreatedDocument"
                            v-if="d.permissions.is_can_create"
                            :extra-sending-data="{
                                      file_type_id: d.file_type.id
                                  }"
                        />

                        <Column :key="d.file_type.id + '-' + displayView" :data="d" v-if="displayView === DISPLAY_COLUMN" :dropzone-id="displayView + '-dropzone-' + d.file_type.id" />

                        <List :key="d.file_type.id + '-' + displayView" :data="d" v-if="displayView === DISPLAY_LIST" :dropzone-id="displayView + '-dropzone-' + d.file_type.id" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Loop File Types -->
    </div>
</template>

<script>
import { cloneDeep, debounce, filter, findIndex, map } from "lodash";
import { useToast } from "vue-toastification";
import Column from "./components/column.vue";
import List from "./components/list.vue";
import OffCanvas from "./components/offcanvas.vue";
import Dropzone from "./components/dropzone.vue";
import { parseErrorMessage } from '../../helpers/errorHandler'
import { makeEllipsis } from '../../helpers/formatter'

const DISPLAY_VIEW_KEY = "document-upload-view";

export default {
    name: "DocumentUploadIndex",
    props: {
        defaultKeyword: String,
        ownerId: Number,
        ownerType: String,
        isArchived: Boolean
    },
    components: {
        Column,
        List,
        OffCanvas,
        Dropzone,
    },
    created() {
        this.search.keyword = this.defaultKeyword;
        if (!_.isEmpty(this.search.keyword)) {
            this.search.isSearchMode = true;
        }
        this.archivedView = this.isArchived;
    },
    data() {
        return {
            windowTop: null,
            DISPLAY_LIST: 'list',
            DISPLAY_COLUMN: 'column',
            data: [],
            loading: false,
            archivedView: false,
            displayView: localStorage.getItem(DISPLAY_VIEW_KEY) || 'list',
            notification: false,
            search: {
                keyword: null,
                isSearching: false,
                isSearchMode: false,
                data: [],
            }
        }
    },
    mounted() {
        this.getDocuments();
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    },
    setup(props) {
        const toast = useToast();

        return {
            toast
        }
    },
    methods: {
        onScroll(e) {
            this.windowTop = window.top.scrollY;
        },
        isCdsFormat(value) {
            return (value.length === 9 || /^\d{3}-\d{3}-\d{9}$/.test(value));
        },
        getNeedUpdateData() {
            const cloneData = cloneDeep(this.data);
            const ref = this;
            let results = [];
            cloneData.forEach(function (d) {
                results = results.concat(filter(d.files, (file) => {
                    if (file.document) {
                        if (file.document.is_required_new_cds.should_fill || file.document.is_required_new_registration.should_fill) {
                            return file.document?.attributes?.cds_number ? !ref.isCdsFormat(file.document?.attributes?.cds_number) : true;
                        } else if (!_.isEmpty(file.document.is_required_tick_cds_update_section)) {
                            let hasFilled = true
                            file.document.is_required_tick_cds_update_section.forEach((item) => {
                                if (item.should_fill) {
                                    hasFilled = hasFilled && !item.is_required;
                                }
                            });
                            return !hasFilled;
                        }
                    }
                    return false;
                }))
            })

            return results;
        },
        getData() {
          return this.search.isSearchMode && !this.loading ? this.search.data : this.data;
        },
        getDocuments() {
            const self = this;
            let currentTop = this.windowTop;
            this.data = [];
            this.loading = true;
            axios.get(`/api/document/${this.ownerType}/${this.ownerId}`, {
                params: {
                    includes: "document",
                    isArchive: self.archivedView
                }
            })
                .then((res) => {
                    this.data = res?.data?.data;
                    if (this.search.isSearchMode) {
                        this.handleSearchResult();
                    }
                })
                .catch((error) => {
                    this.toast.error(parseErrorMessage(error))
                })
                .then(() => {
                    this.loading = false;
                    window.scroll({ top: currentTop, behavior: "instant" });
                    document.body.style.overflowX = "hidden";
                    document.body.style.overflowY = "auto";
                })
        },
        changeArchiveView(value) {
            let urlParams = new URLSearchParams(window.location.search);
            urlParams.set('is_archive', value);
            let changedUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlParams.toString();
            window.location = changedUrl;
        },
        changeView(view) {
            this.displayView = view;
            localStorage.setItem(DISPLAY_VIEW_KEY, this.displayView);
        },
        onFilesSearch: debounce(function (e) {
            const value = e.target.value;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 100);

            if (value) {
                this.search.keyword = _.replace(value.trim(), ', ', ',');
                this.search.isSearchMode = true;
            } else {
                this.search.keyword = null;
                this.search.isSearchMode = false;
                return;
            }

            this.handleSearchResult();
        }, 500),
        handleSearchResult() {
            this.loading = true;
            const values = _.split(this.search.keyword, ',');
            if (values === Array && values.length <= 0) {
                return;
            }

            if (!values) {
                return ;
            }

            let results = [];
            for (const value of values) {
                const cloneData = cloneDeep(this.data);
                if (!value) {
                    continue;
                }

                let result = map(cloneData, (d) => {
                    if (d.file_type.name.toLowerCase().includes(value.toLowerCase())) {
                        return d;
                    }

                    d.files = filter(d.files, (f) => {
                        return f.file_name.toLowerCase().includes(value.toLowerCase()) || f.export_file_name.toLowerCase().includes(value.toLowerCase());
                    });
                    if (d.files.length <= 0) {
                        return d;
                    }
                    return d;
                });

                result = filter(result, (d) => {
                    return d.files.length > 0 || d.file_type.name.toLowerCase().includes(value.toLowerCase());
                });
                results = results.concat(result);
            }
            this.search.data = results;
            this.loading = false;
        },
        handleDeletedDocument(value) {
            this.getDocuments();
            this.toast.success('Document deleted successfully.');
        },
        handleCreatedDocument(value) {
            this.getDocuments();
            this.toast.success('Document Uploaded successfully.');
        },
        handleReplacedDocument(value) {
            this.getDocuments();
            this.toast.success('Document replaced successfully.');
        },
        handleUpdatedDocument(value) {
            this.getDocuments();
            this.toast.success('Document updated successfully.');
        }
    }
}
</script>

<style scoped>

</style>
