<template>
        <vue-easy-lightbox
            moveDisabled
            :visible="visibleRef"
            :imgs="imgsRef"
            :index="indexRef"
            @hide="onHide"
        ></vue-easy-lightbox>
        <div :style="{ 'background-image': 'url(' + src + ')'}"
             :class="imgClass"
             @click="showSingle">
        </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css';
import { ref } from 'vue';

export default {
    name: "SingleLightBox",
    props: {
        src: String,
        alt: String,
        imgClass: String,
    },
    components: {
        VueEasyLightbox,
    },
    setup(props) {
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = () => {
            imgsRef.value = props.src;
            onShow();
        }
        const onHide = () => (visibleRef.value = false)

        return {
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            onHide,
        }
    }
}
</script>

<style scoped>

</style>
