<template>
    <!-- Confirmation modal -->
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmationModal" aria-hidden="true" ref="modal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="width: 500px;">
                <div class="modal-header modal-colored-header bg-danger">
                    <h4 class="modal-title"><slot name="header">Confirmation</slot></h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">
                    <slot name="body">Are you sure?</slot>
                    <br />
                    Please complete this action by entering your login password.
                    <br />
                    <label for="current_password" class="form-label required">Current Password</label>
                    <input id="current_password" type="password" class="form-control" v-model="currentPassword" v-bind:class="{ 'is-invalid' : hasError === true }" />
                    <span class="invalid-feedback" role="alert"><strong>Please enter current password</strong></span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="btnCancel" @click="$emit('close')">No</button>
                    <button type="button" class="btn btn-danger" id="btnConfirm" @click="onConfirm">Yes, confirm</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>

<script>
import { Modal } from "bootstrap";

export default {
    name: "ConfirmationModal",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["confirm", "close"],
    data() {
        return {
            modalInstance: null,
            currentPassword: null,
            hasError: false,
        }
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.modal);
    },
    watch: {
        show(newValue) {
            newValue ? this.modalInstance.show() : this.modalInstance.hide();
        }
    },
    methods: {
        onConfirm() {
            if (!this.currentPassword || this.currentPassword.length <= 0) {
                this.hasError = true;
                return;
            }

            this.$emit('confirm', this.currentPassword);
            this.hasError = false;
            this.currentPassword = null;
        }
    }
}
</script>

<style scoped>
    .modal {
        text-align: left;
    }

    .modal .modal-body {
        font-size: 14px;
    }
</style>
