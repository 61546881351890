<template>
    <div>
        <select class="form-control" :class="className" :id="id" :name="name" :disabled="disabled" :required="required"></select>
    </div>
</template>

<script>
import $ from 'jquery';
import select2 from 'select2';

window.$ = window.jQuery = $;
select2($);

export default {
    name: 'Select2',
    data() {
        return {
            instance: null
        };
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Array], // previously was `value: String`
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: '',
        }
    },
    watch: {
        options: {
            handler(val) {
                this.setOption(val);
            },
            deep: true
        },
        modelValue: {
            handler(val) {
                this.setValue(val);
            },
            deep: true
        },
    },
    methods: {
        setOption(val = []) {
            this.instance.empty();
            this.instance.select2({
                placeholder: this.placeholder,
                ...this.settings,
                data: val
            });
            this.setValue(this.modelValue);
        },
        setValue(val) {
            if (val instanceof Array) {
                this.instance.val([...val]);
            } else {
                this.instance.val([val]);
            }
            this.instance.trigger('change');
        }
    },
    mounted() {
        var vm = this;
        this.instance = $(this.$el)
            .find('select')
            .select2({
                placeholder: this.placeholder,
                ...this.settings,
                data: this.options
            })
            .on('select2:select select2:unselect', ev => {
                vm.$emit('update:modelValue', this.instance.val());
                vm.$emit('select', ev['params']['data']);
            });
        this.setValue(this.modelValue);
    },
    beforeUnmount() {
        this.instance.select2('destroy');
    }
};
</script>
