<template>
    <div class="text-start mt-2">
        <p class="text-muted mb-1 font-14">
            <strong>Status :</strong>
            <span class="ms-2" v-if="this.loading"><i class="mdi mdi-loading mdi-spin"></i> loading..</span>
            <span class="ms-2" v-if="this.data?.connected" style="color: darkgreen; font-weight: bold"><i class="mdi mdi-circle"></i> Connected</span>
            <span class="ms-2" v-if="!this.loading && !this.data?.connected" style="color: darkgray; font-weight: bold"><i class="mdi mdi-circle"></i> Offline</span>
        </p>
        <p v-if="this.getMode() === 'dual'" class="text-muted mb-1 font-14">
            <strong>Table Name :</strong>
            <span class="ms-2">
                {{ this.name ?? 'unknown' }}
            </span>
        </p>
        <p class="text-muted mb-1 font-14">
            <strong>Table Records :</strong>
            <span class="ms-2">
                {{ this.data ? this.data.total_rows : 'unknown' }}
            </span>
        </p>
        <p v-if="this.getMode() === 'full'" class="text-muted mb-1 font-14">
            <strong>Total Synced Rows :</strong>
            <span class="ms-2">
                {{ this.totalSynced ?? '0' }}
            </span>
        </p>
        <p v-if="this.getMode() === 'full'" class="text-muted mb-1 font-14">
            <strong>Total Processed Rows :</strong>
            <span class="ms-2">
                {{ this.totalProcessed ?? '0' }}
            </span>
        </p>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import _ from "lodash";
import {parseErrorMessage} from "../../helpers/errorHandler";

export default {
    name: "ExternalDataSyncStatusBox",
    props: {
        name: String,
        mode: String,
        urlRoute: String,
        totalSynced: String,
        totalProcessed: String,
    },
    mounted() {
        this.getData();
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            loading: false,
            data: null,
        }
    },
    methods: {
        getMode() {
            if (_.isEmpty(this.mode)) {
               return 'full';
            }
            return this.mode
        },
        getData() {
            this.loading = true;
            axios.get(this.urlRoute).then((res) => {
                this.data = res?.data;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.toast.error(parseErrorMessage(error))
            })
        },
    }
}
</script>
