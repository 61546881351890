<template>
    <div>
        <!-- start file preview template -->
        <div class="d-none" :id="'list-preview-template-' + data.file_type.id">
            <tr class="dz-image-preview">
                <td colspan="4">
                    <div class="cursor-pointer my-preview-item">
                        <img data-dz-thumbnail :src="fileIconImg" style="object-fit: contain;object-position: center;padding: 5px;" class="avatar-md rounded bg-light" alt="preview" />
                        <span data-dz-name class="ms-1" />
                        <div class="dz-error-message text-danger"><span data-dz-errormessage /></div>
                    </div>
                    <div class="modal fade preview-modal" tabindex="-1" role="dialog">
                        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header modal-colored-header bg-dark">
                                    <h5 class="modal-title">Preview</h5>
                                </div>
                                <div class="modal-body" style="padding: 0">
                                    <div class="upload-iframe-container"></div>
                                </div>
                                <div class="modal-footer" style="background: transparent; position: absolute; bottom: 0; right: 20px;">
                                    <button type="button" class="btn btn-danger btn-lg my-cancel-button" data-bs-dismiss="modal">Reject</button>
                                    <button type="button" class="btn btn-success btn-lg my-confirm-button" data-bs-dismiss="modal">Verified</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td data-dz-size class="text-center"></td>
                <td class="dz-error-mark text-center">
                    <a href="javascript:void(0);"
                       data-bs-toggle="tooltip"
                       data-dz-remove
                       title="Delete" class="btn btn-link text-danger btn-sm">
                        <i class="fa-solid fa-xmark"></i>
                    </a>
                </td>
            </tr>
        </div>
        <!-- end file preview template -->

        <!-- start Preview Container -->
        <div class="table-responsive mt-2" >
            <table class="table table-hover table-sm table-centered mb-0 text-nowrap document-list-table border">
                <thead class="table-primary">
                    <tr>
                        <th scope="col" width="40%">File</th>
                        <th scope="col" width="10%" class="text-center">Version</th>
                        <th scope="col" width="10%" class="text-center">Stacked</th>
                        <th scope="col" width="20%" class="text-center">Last Updated At</th>
                        <th scope="col" width="10%" class="text-center">Size</th>
                        <th scope="col" width="10%" class="text-center">Extension</th>
                    </tr>
                </thead>
                <tbody :id="'list-previews-container-' + data.file_type.id">
                    <tr v-for="(file, index) in data.files" class="cursor-pointer" data-bs-toggle="offcanvas"
                        :data-bs-target="'#off-canvas-' + file.id"
                        aria-controls="offcanvasRight">
                        <td width="40%" class="text-wrap">
                            <div class="d-flex gap-1 align-items-center">
                                <div class="avatar-sm">
                                    <div v-if="file.is_image" :id="'preview-' + file.document.id"
                                         :style="{ 'background-image': 'url(' + file.src_content + ')'}"
                                         class="avatar-title bg-secondary rounded thumbnail-background-image">
                                    </div>
                                    <span v-else class="avatar-title bg-secondary rounded" >{{ file.extension }}</span>
                                </div>
                                <div class="d-flex flex-column">
                                    <div>
                                        <span >{{ file.export_file_name }}</span>
                                        <i class="fa-solid fa-circle-exclamation text-danger ms-1" v-if="getHasRequiredData(file)"></i>
                                    </div>
                                    <div class="text-warning" data-bs-toggle="tooltip" :title=getHasWarning(file) v-if="getHasWarning(file)">
                                        <i class="fa-regular fa-circle-question"></i>
                                        {{ getHasWarning(file) }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">{{ file.version }}</td>
                        <td class="text-center">{{ file.document.metadata.data.repo_stacked_version }}</td>
                        <td class="text-center">{{ file.updated_at }}</td>
                        <td class="text-center">{{ file.file_size }}</td>
                        <td class="text-center">{{ file.extension }}</td>
                    </tr>
                    <tr v-if="data.files.length <= 0"  :id="'noFilePlaceholder-' + dropzoneId">
                        <td colspan="6">There is no file available to view.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- End Preview Container -->

        <div class="d-flex justify-content-end d-none" :id="'sectionUpload-' + dropzoneId">
            <button class="btn btn-outline-dark mt-2" :id="'btnUpload-' + dropzoneId">Upload</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "DocumentUploadList",
    props: ['data', 'createDocumentUrl', 'fileTypeId', 'onCreatedDocument', 'dropzoneId'],
    components: {
    },
    data() {
        return {
            fileIconImg: '/images/elements/file-icon.png',
        }
    },
    methods: {
        isCdsFormat(value) {
            return (value.length === 9 || /^\d{3}-\d{3}-\d{9}$/.test(value));
        },
        getHasRequiredData(file) {
            if (file && file.document) {
                if (file.document.is_required_new_cds.should_fill || file.document.is_required_new_registration.should_fill) {
                    return file.document?.attributes?.cds_number ? !this.isCdsFormat(file.document?.attributes?.cds_number) : true;
                } else if (!_.isEmpty(file.document.is_required_tick_cds_update_section)) {
                    let hasFilled = true
                    file.document.is_required_tick_cds_update_section.forEach((item) => {
                        if (item.should_fill) {
                            hasFilled = hasFilled && !item.is_required;
                        }
                    });
                    return !hasFilled;
                }
            }
            return false;
        },
        getHasWarning(file) {
            if (file && file.document) {
                return !_.isEmpty(file.document.has_warning) ? file.document.has_warning : false;
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>
