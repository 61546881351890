<template>
    <div class="btn-group mb-2">
        <button v-for="option in ownerTypeOptions" type="button" class="btn text-capitalize" @click="handleSelectOwnerType(option)" :class="{'btn-primary': option === selectedOwnerType, 'btn-light': option !== selectedOwnerType }">
            {{ option }}
        </button>
    </div>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-9 ps-2 pe-2 pe-lg-1">
            <v-select
                style="padding: 1px; font-size: 16px;"
                class="form-control"
                placeholder="Enter Name\Code to search..."
                :clearSearchOnSelect="true"
                :resetOnOptionsChange="true"
                :filterable="false"
                :selectOnTab="true"
                :options="search.data"
                :value="selectedOwner?.label"
                v-model="selectedOwner"
                @search="onSearchOwner"
            >
                <template #no-options="{ search, searching }">
                    <template v-if="searching">
                        No results found for <em>{{ search }}</em
                    >
                    </template>
                    <em v-else>Start typing to search.</em>
                </template>

                <template #option="option">
                   <div style="display: flex; justify-content: flex-start; align-items: center">
                       <span style="flex: 0.1" v-html="boldMatchText(option.code)"></span>
                       <span style="flex: 0.5" v-html="boldMatchText(option.name)"></span>
                       <span style="flex: 0.4" v-html="option.full_cds_number ? boldMatchText(option.full_cds_number) : ''"></span>
                   </div>
                </template>

                <template #selected-option="option">
                    <div class="selected">
                        {{ option.label }}
                    </div>
                </template>
            </v-select>
        </div>
        <div class="col-12 col-md-12 col-lg-3 mt-2 mt-lg-0 pe-2 ps-2 ps-lg-1 text-end">
            <button type="button" class="btn btn btn-outline-success" @click="handleSwitchOwner()" style="width: 100%;">Enter</button>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {parseErrorMessage} from "../../helpers/errorHandler";
import { debounce, isEmpty } from "lodash";

export default {
    name: "OwnerSearchBox",
    props: {
        defaultOwnerTypeSelected: String,
        ownerTypeOptions: Array,
        documentUploadRoute: String,
    },
    data() {
        return {
            loading: false,
            selectedOwnerType: null,
            selectedOwner: null,
            search: {
                keyword: null,
                data: [],
            }
        }
    },
    created() {
        this.selectedOwnerType = this.defaultOwnerTypeSelected;
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    methods: {
        getOwnerList(loading) {
            if (!isEmpty(this.search.keyword)) {
                loading(true);
                this.search.data = [];
                axios.post(`/api/${this.selectedOwnerType}`, {
                    'keyword': this.search.keyword
                }).then((res) => {
                    this.search.data = res.data.data;
                    if (isEmpty(res.data.data)) {
                        this.toast.info( `No results found for "${this.search.keyword}"`)
                    }
                }).catch((error) => {
                    this.toast.error(parseErrorMessage(error))
                }).finally(() => {
                    loading(false);
                })
            }
        },
        onSearchOwner: debounce(function (search, loading) {
            const keyword = search.trim();
            if (this.search.keyword == keyword) return;
            this.search.keyword = keyword;
            // this.selectedOwner = null;
            this.getOwnerList(loading);
        }, 350),
        // onSelectOwner(item) {
        //     this.selectedOwner = item;
        // },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        },
        boldMatchText(text) {
            const regexp = new RegExp(`(${this.escapeRegExp(this.search.keyword)})`, 'ig');
            return text.replace(regexp, '<strong>$1</strong>');
        },
        handleSelectOwnerType(type) {
            this.selectedOwnerType = type;
        },
        handleSwitchOwner() {
            let urlParams = new URLSearchParams(window.location.search);
            let isArchived = urlParams.get('is_archive');

            if (isEmpty(isArchived)) isArchived = 0;
            isArchived = isArchived == 1 ? 1 : 0;
            if (this.selectedOwner) {
                window.location = `${this.documentUploadRoute}?owner_type=${this.selectedOwnerType}&owner_id=${this.selectedOwner.id}&is_archive=${isArchived}`;
            }
        }
    }
}
</script>

<style scoped>
>>> {
    --vs-selected-bg: lightskyblue;
    --vs-selected-color: #eeeeee;

    --vs-dropdown-option--active-bg: lightskyblue;
    --vs-dropdown-option--active-color: #eeeeee;
}
</style>
