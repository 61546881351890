<template>
    <div class="modal modal-xl fade" tabindex="-1" role="dialog" ref="ccdWorkflowModal" id="ccd-workflow-modal" @keydown.esc="hideModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Classification - {{ branch_classification?.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"></button>
                </div>
                <div class="modal-body">
                    <div class="row g-2">
                        <div class="col-6">
                            <label class="form-label required">Min Amount</label>
                            <input class="form-control" type="number" v-model="form.min_amount" required />
                            <span class="help-block"><small>Eg. 0</small></span>
                        </div>
                        <div class="col-6">
                            <label class="form-label required">Max Amount</label>
                            <input class="form-control" type="number" v-model="form.max_amount" required />
                            <span class="help-block"><small>Eg. 50000</small></span>
                        </div>
                        <div class="col-12">
                            <label class="form-label required">Number of Approval</label>
                            <input class="form-control" type="number" placeholder="Min: 1, Max: 2"  v-model="form.number_approves" />
                        </div>
                        <div class="col-12 mt-2">
                            <label class="form-label">Settings</label>
                            <button class="btn btn-sm btn-primary ms-2" @click="addNewSetting">Add</button>
                        </div>
                        <div class="col-12">
                            <input id="differentBranch" v-model="form.settings.is_must_different_department" type="checkbox" class="form-check-input">
                            <label for="differentBranch" class="form-check-label ms-1">Must Different Department</label>
                        </div>
                        <div class="col-12" v-for="(setting, index) in form.settings.items" :key="index">
                           <div class="row">
                               <div class="col-md-4">
                                   <label class="form-label">Department</label>
                                   <select2 :options="departmentOptions"
                                            v-model="form.settings.items[index].department_id"
                                            :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: false}"/>
                               </div>
                               <div class="col-md-4">
                                   <label class="form-label">Position</label>
                                   <select2 :options="positionOptions"
                                            v-model="form.settings.items[index].position_id"
                                            :settings="{ allowClear: true, placeholder: 'Select one or more', multiple: false}"/>
                               </div>
                               <div class="col-md-2 d-flex flex-column mt-auto">
                                   <div>
                                       <input :id="`isFirstApprovalOnly-${index}`" v-model="form.settings.items[index].is_first_approval_only" type="checkbox" class="form-check-input">
                                       <label :for="`isFirstApprovalOnly-${index}`" class="form-check-label ms-1">First Approval Only</label>
                                   </div>
                                   <div>
                                       <input :id="`isHqOnly-${index}`" v-model="form.settings.items[index].is_hq_only" type="checkbox" class="form-check-input">
                                       <label :for="`isHqOnly-${index}`" class="form-check-label ms-1">HQ Only</label>
                                   </div>
                               </div>
                               <div class="col-md-2 d-flex align-items-end justify-content-end">
                                   <button class="btn btn-sm btn-outline-danger" @click="onRemoveSetting(index)">Remove</button>
                               </div>
                           </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="submit" :disabled="isLoading">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import select2 from "../Select2.vue";
import { useToast } from "vue-toastification";

export default {
    name: "CcdWorkflowCreate",
    props: {
        branch_classification: {
            type: Object,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        departmentOptions: Array,
        positionOptions: Array,
    },
    components: {
        select2,
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    data: () => ({
        modalInstance: null,
        isLoading: false,
        form: {
            min_amount: null,
            max_amount: null,
            branch_classification_code: null,
            number_approves: null,
            settings: {
                is_must_different_department: false,
                items: [
                    {
                        department_id: null,
                        position_id: null,
                        is_hq_only: false,
                        is_first_approval_only: false,
                    }
                ]
            },
        },
    }),
    watch: {
        visible(newValue, oldValue) {
            if (newValue) {
                this.modalActive();
                this.form.branch_classification_code = this.branch_classification.value;
            }
        }
    },
    methods: {
        modalActive: function() {
            this.modalInstance = new Modal(this.$refs.ccdWorkflowModal, {
                target: "#ccd-workflow-modal",
                backdrop: "static"
            });
            this.modalInstance.show()
        },
        hideModal: function() {
            this.modalInstance.hide();
            this.$emit('closeModal');
        },
        addNewSetting: function() {
            const data = {
                department_id: null,
                position_id: null,
                is_hq_only: false,
                is_first_approval_only: false,
            }
            this.form.settings.items.push(data);
        },
        onRemoveSetting: function(index) {
            this.form.settings.items.splice(index, 1);
        },
        submit: function() {
            this.isLoading = true;
            axios.post(`/api/ccd-workflow`, this.form)
                .then(({ data }) => {
                    this.toast.success("Created successfully.");
                    this.form = {
                        min_amount: null,
                        max_amount: null,
                        branch_classification_code: null,
                        number_approves: null,
                        settings: {
                            is_must_different_department: false,
                            items: [
                                {
                                    department_id: null,
                                    position_id: null,
                                    is_hq_only: false,
                                    is_first_approval_only: false,
                                }
                            ]
                        },
                    };
                    this.$emit('created');
                    this.hideModal();
                }).catch((e) => {
                 const msg = e?.response?.data?.message;
                 this.toast.error(msg);
            })
            .finally(() => {
                this.isLoading = false;
            });
        }
    }
}
</script>

<style scoped>
</style>
