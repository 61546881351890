<template>
    <div class="mt-2">
        <!-- start file preview template -->
        <div class="d-none" :id="'column-preview-template-' + data.file_type.id">
            <div class="col-12 col-md-2 cursor-pointer dz-image-preview">
                <div class="card h-100 mb-0 shadow-none border">
                    <div class="d-flex flex-column cursor-pointer p-1 position-relative align-items-center text-center">
                        <a href="javascript:void(0);"
                           data-bs-toggle="tooltip"
                           data-dz-remove=""
                           title="Delete" class="btn btn-link text-danger btn-lg position-absolute top-0 end-0">
                            <i class="fa-solid fa-xmark"></i>
                        </a>
                        <div class="d-flex flex-md-column align-items-md-center gap-1 my-preview-item">
                            <div class="avatar-md">
                                <img data-dz-thumbnail :src="fileIconImg" style="object-fit: contain;object-position: center;padding: 5px;" class="avatar-md rounded bg-white" alt="preview">
                            </div>
                            <div class="">
                                <a href="javascript:void(0);" class="text-muted fw-bold text-break" data-dz-name></a>
                                <p class="mb-0" data-dz-size></p>
                            </div>
                        </div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message text-danger text-md-center"><span data-dz-errormessage></span></div>
                    </div>
                    <div class="modal fade preview-modal" tabindex="-1" role="dialog">
                        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header modal-colored-header bg-dark">
                                    <h5 class="modal-title">Preview</h5>
                                </div>
                                <div class="modal-body" style="padding: 0">
                                    <div class="upload-iframe-container"></div>
                                </div>
                                <div class="modal-footer" style="background: transparent; position: absolute; bottom: 0; right: 20px;">
                                    <button type="button" class="btn btn-danger btn-lg my-cancel-button" data-bs-dismiss="modal">Reject</button>
                                    <button type="button" class="btn btn-success btn-lg my-confirm-button" data-bs-dismiss="modal">Verified</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- end file preview template -->

        <vue-easy-lightbox
            moveDisabled
            :visible="visibleRef"
            :imgs="imgsRef"
            :index="indexRef"
            @hide="onHide"
        ></vue-easy-lightbox>
        <!-- Preview -->
        <div class="row g-2" :id="'column-previews-container-' + data.file_type.id">
            <div v-for="(file, index) in data.files" class="col-12 col-md-2">

                <div class="card h-100 mb-0 shadow-none border p-1">
                    <div class="d-flex flex-md-column align-items-md-center gap-1 h-100">
                        <div class="avatar-md">
                            <div v-if="file.is_image" :id="'preview-' + file.document.id"
                                 :style="{ 'background-image': 'url(' + file.src_content + ')'}"
                                 class="avatar-title bg-secondary rounded thumbnail-background-image"
                                 @click="showMultiple(data.files, index)">
                            </div>
                            <span v-else class="avatar-title bg-secondary rounded cursor-pointer" data-bs-toggle="offcanvas"
                                  :data-bs-target="'#off-canvas-' + file.id"
                                  aria-controls="offcanvasRight">
                                {{ file.extension }}
                            </span>
                        </div>
                        <div class="text-md-center text-sm mt-auto cursor-pointer w-100" data-bs-toggle="offcanvas"
                             :data-bs-target="'#off-canvas-' + file.id"
                             aria-controls="offcanvasRight">
                            <a href="javascript:void(0);" class="text-muted fw-bold">{{ file.export_file_name }}</a>
                            <i class="fa-solid fa-circle-exclamation text-danger ms-1" v-if="getHasRequiredData(file)"></i>
                            <div class="text-warning" data-bs-toggle="tooltip" :title=getHasWarning(file) v-if="getHasWarning(file)">
                                <i class="fa-regular fa-circle-question"></i>
                                {{ getHasWarning(file).substring(0, 30) + "..." }}
                            </div>
                            <p class="mb-0">{{ file.file_size }}</p>
                            <p class="mb-0 text-muted">{{ file.updated_at }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="data.files.length <= 0" :id="'noFilePlaceholder-' + dropzoneId">There is no file available to view.</div>
        </div>
        <div class="d-flex justify-content-end d-none" :id="'sectionUpload-' + dropzoneId">
            <button class="btn btn-outline-dark mt-2" :id="'btnUpload-' + dropzoneId">Upload</button>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
// you need to import css yourself
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import { ref } from 'vue';

export default {
    name: "DocumentUploadColumn",
    props: ['data', 'createDocumentUrl', 'fileTypeId', 'onCreatedDocument', 'dropzoneId'],
    components: {
        VueEasyLightbox
    },
    data() {
        return {
            fileIconImg: '/images/elements/file-icon.png',
        }
    },
    setup(props) {
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = (file) => {
            imgsRef.value = file.src_content;
            onShow();
        }
        const showMultiple = (files, index) => {
            imgsRef.value = files.map((f) => f.src_content);
            indexRef.value = index // index of imgList
            onShow()
        }
        const onHide = () => (visibleRef.value = false)
        return {
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            showMultiple,
            onHide,
        }
    },
    methods: {
        isCdsFormat(value) {
            return (value.length === 9 || /^\d{3}-\d{3}-\d{9}$/.test(value));
        },
        getHasRequiredData(file) {
            if (file && file.document) {
                if (file.document.is_required_new_cds.should_fill || file.document.is_required_new_registration.should_fill) {
                    return file.document?.attributes?.cds_number ? !this.isCdsFormat(file.document?.attributes?.cds_number) : true;
                } else if (!_.isEmpty(file.document.is_required_tick_cds_update_section)) {
                    let hasFilled = true
                    file.document.is_required_tick_cds_update_section.forEach((item) => {
                        if (item.should_fill) {
                            hasFilled = hasFilled && !item.is_required;
                        }
                    });
                    return !hasFilled;
                }
            }
            return false;
        },
        getHasWarning(file) {
            if (file && file.document) {
                return !_.isEmpty(file.document.has_warning) ? file.document.has_warning : false;
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>
