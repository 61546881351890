import './bootstrap';
import $ from 'jquery';
import 'jquery-mask-plugin';
import axios from 'axios';
import select2 from 'select2';
import Dropzone from 'dropzone';
import ClipboardJS from "clipboard";
import ScrollBooster from 'scrollbooster';
import multiDownload from "multi-download";
import tinymce from 'tinymce';
import SignaturePad from 'signature_pad';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/help/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'jquery-typeahead/dist/jquery.typeahead.min'
import 'jquery-typeahead/dist/jquery.typeahead.min.css'
import './vue';

window.$ = window.jQuery = $;
window.Dropzone = Dropzone;
window.multiDownload = multiDownload;
window.axios = axios;

// clipboard
var clipboard =  new ClipboardJS('.copy-btn');
clipboard.on('success', function(e) {
    alert('Copied Text: ' + e.text);
    e.clearSelection();
});

// select2
select2($);
$('[data-toggle="select2"]').select2({
    theme: "bootstrap-5"
});

// tinyMce
tinymce.init({
    selector: 'textarea.html_content',
    height: 300,
    menubar: false,
    plugins: ['help wordcount autoresize lists'],
    toolbar: 'undo redo | formatselect | fontsizeselect | ' +
        'bold italic underline backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
    content_css: window.location.origin + '/css/codepen.min.css',
    skin: false
});

// ScrollBooster
if (document.querySelector('.scroll-viewport') && document.querySelector('.scroll-content')) {
    var scrollView = new ScrollBooster({
        viewport: document.querySelector('.scroll-viewport'),
        content: document.querySelector('.scroll-content'),
        scrollMode: 'native',
        direction: 'horizontal'
    });
}

// SignaturePad
if (document.getElementById('signature-pad')) {
    const canvas = document.getElementById('signature-pad');
    window.signaturePad = new SignaturePad(canvas);
}

$(document).ready(function() {
    //Pop Overs
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });

    // tooltip
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    //disable image right click
    $('img').bind('contextmenu', function(e) {return false;});
    $(document).on('contextmenu', 'img', function() {return false;})

    //disable iframe right click
    $('embed').bind('contextmenu', function(e) {return false;});
    $(document).on('contextmenu', 'embed', function() {return false;})

    //dropzon
    $('[data-plugin="dropzone"]').each(function () {
        const id = $(this).attr('id')
        const actionUrl = $(this).attr('action')
        const previewContainer = $(this).data('previewsContainer');
        const opts = { url: actionUrl };
        if (previewContainer) {
            opts['previewsContainer'] = previewContainer;
        }

        const uploadPreviewTemplate = $(this).data("uploadPreviewTemplate");
        if (uploadPreviewTemplate) {
            opts['previewTemplate'] = $(uploadPreviewTemplate).html();
        }

        const maxFiles = $(this).data("maxFiles");
        if (maxFiles) {
            opts['maxFiles'] = maxFiles;
        }
        Dropzone.autoDiscover = false;

        const dropzoneEl = new Dropzone(`#${id}`, opts)
    });

    //input password toggle
    $("[data-password]").on('click', function () {
        if ($(this).attr('data-password') == "false") {
            $(this).siblings("input").attr("type", "text");
            $(this).attr('data-password', 'true');
            $(this).addClass("show-password");
        } else {
            $(this).siblings("input").attr("type", "password");
            $(this).attr('data-password', 'false');
            $(this).removeClass("show-password");
        }
    });

    //input mask
    $('[data-toggle="input-mask"]').each(function (idx, obj) {
        var maskFormat = $(obj).data("maskFormat");
        var reverse = $(obj).data("reverse");
        if (reverse != null)
            $(obj).mask(maskFormat, { 'reverse': reverse });
        else
            $(obj).mask(maskFormat);
    });
});
