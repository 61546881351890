<template>
    <!-- start dropzone -->
    <form method="post"
          :id="dropzoneId"
          class="dropzone dz-clickable document-create"
          :class="isReachedLimit ? 'dz-max-files-reached' : '' "
    >
        <div class="dz-message needsclick">
            <i class="h3 text-muted dripicons-cloud-upload"></i>
            <h5 v-if="isReachedLimit">
                The number of files has been reached. <br/>Replace or delete the existing file.
            </h5>
            <h4 v-else>{{ placeholder ? placeholder : "Drop files here or click to upload." }}</h4>
        </div>
    </form>
    <!-- end dropzone -->
</template>

<script>
import Dropzone from "dropzone";
import { map, isEqual, isEmpty } from "lodash";
import { parseErrorMessage } from "../../../helpers/errorHandler";
import {useToast} from "vue-toastification";

export default {
    name: "DocumentUploadDropzone",
    props: {
        dropzoneId: String,
        previewsContainerId: String,
        previewTemplateId: String,
        maxFiles: Number,
        maxFileSize: Number,
        allowedExtensions: Array,
        isReachedLimit: Boolean,
        placeholder: String,
        uploadMethod: String,
        uploadDocumentUrl: String,
        extraSendingData: Object,
        skipConfirmation: Boolean,
    },
    data() {
        return {
            uploadingFileImg: '/images/elements/uploading.gif',
        }
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    mounted() {
        const self = this;
        var openedModal = 0;
        var queueFiles = [];

        Dropzone.createElement = function(string) {
            var el = $(string);
            return el[0];
        };
        const dropzone = new Dropzone(`#${this.dropzoneId}`, {
            url: this.uploadDocumentUrl,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            method: this.uploadMethod || "post",
            maxFiles: this.maxFiles,
            maxFilesize: this.maxFileSize,
            acceptedFiles: this.allowedExtensions,
            previewsContainer: document.querySelector(`#${this.previewsContainerId}`),
            previewTemplate: document.querySelector(`#${this.previewTemplateId}`).innerHTML,
            autoProcessQueue: false,
            parallelUploads: 10,
        });

        dropzone.on("error", (file, message) => {
            if (file.previewElement) {
                file.previewElement.classList.add("dz-error");
                message = parseErrorMessage(message);
                for (let node of file.previewElement.querySelectorAll("[data-dz-errormessage]"))node.textContent = message;
            }
        });

        dropzone.on('success', (file, response) => {
            if (dropzone.getQueuedFiles().length <= 0) {
                file.previewElement.remove();
                dropzone.removeFile(file);
                if (dropzone.getAcceptedFiles().length <= 0) {
                    this.$emit('file-uploaded', response)
                }
            }
            queueFiles = [];
        });

        dropzone.on('sending', (file, xhr, formData) => {
            formData.append('includes', 'document');
            if (this.extraSendingData) {
                map(this.extraSendingData, (v, k) => formData.append(k, v));
            }
        });

        dropzone.on("removedfile", (file) => {
            if (file.status !== 'success') {
                self.toast.warning('Removed : ' + file.name)
            }
        });

        dropzone.on('addedfile', (file) => {
            const submitButtonDiv = document.querySelector(`#sectionUpload-${this.dropzoneId}`);
            const placeholder = document.querySelector(`#noFilePlaceholder-${this.dropzoneId}`);
            const container = $(`#${this.previewsContainerId}`);

            // show upload button
            if (submitButtonDiv) {
                submitButtonDiv.classList.remove('d-none');
            }

            // remove placeholder row
            if (placeholder) {
                placeholder.classList.add('d-none');
            }

            // get iframe in modal
            const iframeContainer = container.children().last().find('.upload-iframe-container').first();
            const previewContainer = container.children().last().find('.my-preview-item').first();
            const previewModal = container.children().last().find('.preview-modal').first();
            const previewAcceptBtn = previewModal.find('.my-confirm-button').first();
            const previewRemoveBtn = previewModal.find('.my-cancel-button').first();

            const modal = previewModal.length >= 1 ? new bootstrap.Modal(previewModal, {
                keyboard: false
            }) : null;

            previewAcceptBtn.on('click', function (event) {
                modal.hide(previewModal);
            })

            previewRemoveBtn.on('click', function (event) {
                dropzone.removeFile(file);
            })

            previewModal.on('show.bs.modal', function (event) {
                openedModal++;
            })

            previewModal.on('hidden.bs.modal', function (event) {
                openedModal--;

                if (openedModal === 0 && dropzone.getQueuedFiles().length > 0) {
                    if (isEmpty(queueFiles) || !isEqual(queueFiles, dropzone.getQueuedFiles())) {
                        if (self.skipConfirmation || confirm('Do you want process to upload files?')) {
                            dropzone.processQueue();
                        }
                        queueFiles = dropzone.getQueuedFiles();
                    }
                }
            })

            if (iframeContainer) {
                iframeContainer.css("height", "100%");
                iframeContainer.css("display", "flex");
                iframeContainer.css("justify-content", "center");
                iframeContainer.css("align-items", "center");

                if (file.type.includes('image/')) {
                    const image = document.createElement('img');
                    image.setAttribute('alt', file.name);
                    image.setAttribute('src', URL.createObjectURL(file));
                    image.setAttribute('style', 'max-width: 760px');
                    iframeContainer.css("padding", "15px");
                    iframeContainer.append(image);

                    let zoom = 1;
                    const zoomingSpeed = 0.05;
                    iframeContainer.on("mousewheel", (e)=> {
                        if (e.originalEvent.deltaY > 0 && zoom < 2) {
                            zoom += zoomingSpeed;
                        } else if (e.originalEvent.deltaY <= 0 && zoom > 0.5)  {
                            zoom -= zoomingSpeed;
                        }

                        iframeContainer.css('transform', `scale(${zoom})`);
                    })

                    previewContainer.on('click', function(e) {
                        if (modal) {
                            modal.show(previewModal);
                        } else {
                            self.toast.error('Modal Not found!')
                        }
                    });
                    modal.show(previewModal);
                } else if (file.type.includes('application/pdf')) {
                    const iframe = document.createElement('iframe');
                    iframe.classList.add('iframe');
                    iframe.setAttribute('frameBorder', 0);
                    iframe.setAttribute('scrolling', 'auto');
                    iframe.setAttribute('src', URL.createObjectURL(file));
                    iframeContainer.append(iframe);

                    previewContainer.on('click', function(e) {
                        if (modal) {
                            modal.show(previewModal);
                        } else {
                            self.toast.error('Modal Not found!')
                        }
                    });
                    modal.show(previewModal);
                } else {
                    iframeContainer.append(document.createTextNode("No preview available"));
                    previewContainer.on('click', function(e) {
                        self.toast.warning('No preview available!')
                    });
                }
            }
        })

        dropzone.on('processing', (file) => {
            file.previewElement.querySelector("img").src = self.uploadingFileImg;
        });

        dropzone.on('reset', () => {
            const submitButtonDiv = document.querySelector(`#sectionUpload-${this.dropzoneId}`);
            const placeholder = document.querySelector(`#noFilePlaceholder-${this.dropzoneId}`);

            // hide upload button
            if (submitButtonDiv) {
                submitButtonDiv.classList.add('d-none');
            }

            // remove placeholder row
            if (placeholder) {
                placeholder.classList.remove('d-none');
            }

            queueFiles = [];
        });

        const submitButton = document.querySelector(`#btnUpload-${this.dropzoneId}`);
        if (submitButton) {
            submitButton.addEventListener("click", function() {
                dropzone.processQueue();
            });
        }
    },
}
</script>

<style scoped>

</style>
