/**
 * Backend Portal Resources
 */
import { createApp } from 'vue/dist/vue.esm-bundler';
import AccountRegistrationComment from './components/AccountRegistration/Comment.vue';
import AccountRegistrationDocument from './components/AccountRegistration/Document.vue';
import AccountRegistrationUserSelector from './components/AccountRegistration/UserSelector.vue';
import ConfirmationModal from './components/ConfirmationModal.vue';
import CcdWorkflowSetting from "./components/CcdWorkflow/setting.vue";
import SingleLightBox from './components/SingleLightBox.vue';
import PdfViewer from './components/PdfViewer.vue';
import DocumentUpload from './components/DocumentUpload/index.vue';
import DocumentSearch from "./components/DocumentSearch/index.vue";
import DocumentOwnerSearchBox from './components/DocumentUpload/OwnerSearchBox.vue';
import ExternalDataSyncStatusBox from './components/ExternalDataSyncStatusBox/index.vue';
import vSelect from 'vue-select'
import Toast from "vue-toastification";
import SimpleTypeahead from 'vue3-simple-typeahead';
import "vue-toastification/dist/index.css";
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import 'vue-select/dist/vue-select.css';


const app = createApp({});
app.component('document-upload', DocumentUpload);
app.component('document-search', DocumentSearch);
app.component('document-owner-search-box', DocumentOwnerSearchBox);
app.component('single-light-box', SingleLightBox);
app.component('pdf-viewer', PdfViewer);
app.component('external-data-sync-status-box', ExternalDataSyncStatusBox);
app.component('v-select', vSelect);
app.component('account-registration-comment', AccountRegistrationComment);
app.component('account-registration-document', AccountRegistrationDocument);
app.component('account-registration-user-selector', AccountRegistrationUserSelector);
app.component('confirmation-modal', ConfirmationModal);
app.component('ccd-workflow-setting', CcdWorkflowSetting);

app.use(Toast, {
    position: 'bottom-right'
});
app.use(SimpleTypeahead);
app.config.globalProperties.$filters = {
    numberToText: function (value) {
        const suffixes = ["", "K", "Mil", "B", "T"];
        let suffixNum = 0;
        while (value >= 1000) {
            value /= 1000;
            suffixNum++;
        }
        return value.toFixed(0) + suffixes[suffixNum];
    }
};
app.mount('#app');

// frontend app
const frontendApp = createApp({});
frontendApp.use(Toast, {
    position: 'bottom-right'
});
frontendApp.mount('#front-end-app');
